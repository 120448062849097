// Range slider customization
$thumb-color: map-get($colors, primary-dark);
$thumb-border-color: white;
$thumb-border-width: 2px;
$track-width: 100%;
$track-height: 4px;
$thumb-height: 30px;
$thumb-width: 30px;

@import './range-slider.scss';

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  background-color: map-get($colors, grey-scale-0);
  color: map-get($colors, grey-scale-4);
  font-size: 13px;
  line-height: 1.5;
  border: none;
  border-radius: 4px;
  &:focus {
    border-color: map-get($colors, primary);
    outline: 0;
    box-shadow: 0 0 0 2px map-get($colors, primary);
  }
  &:disabled {
    opacity: 0.5;
  }
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  // Inlined fa-chevron-down svg
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%234a4a4a" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"/></svg>');
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 13px;
}

input[type='range'] {
  position: relative;
  @include input-type-range();
}
