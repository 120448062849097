@mixin questionnaire-answer($colors) {
  .svi-questionnaire-answer {
    .answer-container {
      .answer-options {
        button {
          background-color: map-get($colors, grey-scale-0);
          border: 1px solid map-get($colors, grey-scale-2);
          color: map-get($colors, grey-scale-4);
          &.active {
            background-color: map-get($colors, primary);
            box-shadow: inset 1px 2px 5px 0 map-get($colors, grey-scale-8);
            color: map-get($colors, grey-scale-0);
          }
        }
      }
    }
    .no-answer {
      button {
        background-color: map-get($colors, grey-scale-0);
        border: 1px solid map-get($colors, grey-scale-2);
        color: map-get($colors, grey-scale-4);
        &.active {
          box-shadow: inset 1px 2px 5px 0 map-get($colors, grey-scale-8);
          background-color: map-get($colors, overlay-black);
        }
      }
    }
    .weight-container-inner {
      .weight-options {
        button {
          background-color: map-get($colors, grey-scale-0); // contrast
          border: 1px solid map-get($colors, grey-scale-2);
          color: map-get($colors, grey-scale-4);
          &.active {
            background-color: map-get($colors, primary-dark);
            color: map-get($colors, grey-scale-0);
          }
        }
      }
      .weight-title {
        color: map-get($colors, grey-scale-4);
      }
    }
  }
}
