:host {
  display: block;
  position: relative;
  z-index: 1;
}
@mixin page-title($colors) {
  .svi-page-title {
    padding: 10px 20px;
    color: map-get($colors, grey-scale-4);
    font-size: 18px;
    @media ($screen-sm-up) {
      background-color: map-get($colors, overlay-black);
      margin-top: 0px;
    }
  }
}
