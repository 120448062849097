@import '~style/colors';

.smartmap {
  width: 100%;
  padding: 20px;
  img {
    max-width: 100%;
  }
  .smartmap-position {
    opacity: 0.8;
    transition: 0.35s;
    cursor: pointer;
  }
  .smartmap-position:hover {
    stroke-width: 2;
  }
}

.smartmap-background {
  fill: $white;
  stroke-width: 2px;
  stroke: $grey-cotton-seed;
}

.smartmap-grid {
  stroke-width: 1px;
  stroke: $grey-cotton-seed;
}

.sv-tooltip {
  max-width: 200px;
  font-size: 14px;
  background-color: $white;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid map-get($colors, overlay-black);
  box-shadow: 0 2px 4px 0 map-get($colors, overlay-black);
}

.smartmap-position-me {
  fill: $grey-tundora;
  opacity: 1 !important;
}
