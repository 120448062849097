@import '~style/variables';
footer.container {
  padding: 32px 0px;
  text-align: center;
}

@mixin footer($colors) {
  .svi-footer {
    margin: 0 auto;
    max-width: $layout-max-width;
  }
  .footer-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .footer-link {
    width: 100%;
    margin-bottom: 10px;
    text-decoration: none;
    color: map-get($colors, grey-scale-4);
  }
  .copyright {
    text-align: center;
    font-size: 10px;
    color: map-get($colors, grey-scale-2);
  }
  @media ($screen-sm-up) {
    .footer-link {
      width: auto;
      margin-right: 20px;
    }
    .footer-link:last-child {
      margin-right: 0px;
    }
  }
}
