@import './variables.scss';

.invisible {
  visibility: hidden;
}

.d-none {
  display: none;
}

// Deprecated
.mobile-visible {
  display: initial;
}
.mobile-hidden {
  display: none;
}

@media ($screen-sm-up) {
  .d-sm-block {
    display: block !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-none {
    display: none !important;
  }
  // Deprecated
  .mobile-visible {
    display: none;
  }
  .mobile-hidden {
    display: initial; // Not supported by ie11
  }
}
