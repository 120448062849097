@mixin matching-list($colors) {
  .svi-matching-results {
    .empty-matching-list {
      background-color: map-get($colors, overlay-black);
    }
    .svi-matching-result-summary {
      color: map-get($colors, grey-scale-4);
      background-color: map-get($colors, overlay-black);
    }
    .methodology-link {
      a {
        color: map-get($colors, grey-scale-4);
      }
    }
  }
  svi-list-item.lastseat {
    border-bottom: 5px solid map-get($colors, grey-scale-4);
  }
}
