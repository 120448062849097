@mixin tab-group($colors) {
  .svi-tab-group-header {
    .tab-label {
      background-color: map-get($colors, overlay-black);
      color: map-get($colors, grey-scale-4);
    }
    .tab-label.active {
      color: black;
      background-color: map-get($colors, overlay-white);
    }
  }
  .svi-tab-content.active {
    background-color: map-get($colors, overlay-white);
  }
  // Theme for matching page
  .svi-matching-results,
  .svi-database-page-content {
    .svi-tab-group-header {
      .tab-label {
        background-color: transparent;
      }
      .tab-label.active {
        background-color: map-get($colors, overlay-white);
      }
    }
  }
}
