@import '~@angular/cdk/overlay-prebuilt.css';

.svi-dialog {
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 8px;

  .tooltip__arrow {
    position: absolute;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      background: white;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }
}
.full-size-pane {
  width: 100%;
  height: 100%;
}

.solid-backdrop {
  background: rgba(0, 0, 0, 0.7);
}
