@import '~style/variables';
:host {
  display: block;
  float: right;
  @media ($screen-sm-up) {
    float: none;
  }
}

@mixin navbar($colors) {
  .svi-navbar {
    display: flex;
    margin-top: 20px;
    .nav-item {
      color: map-get($colors, grey-scale-4);
      padding: 10px 20px;
      font-size: 18px;
      text-decoration: none;
    }
    .nav-item.active {
      background-color: map-get($colors, overlay-black);
      color: black;
    }
    .nav-item.disabled {
      pointer-events: none;
      color: map-get($colors, overlay-grey);
    }
    .nav-back {
      color: map-get($colors, grey-scale-4);
      padding: 14px 20px 10px 20px;
      font-size: 14px;
      cursor: pointer;
      i {
        color: map-get($colors, primary-dark);
      }
    }
    .spacer {
      flex: 1;
    }
  }
}
