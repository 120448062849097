/* You can add global styles to this file, and also import other style files */
@import './style/base.scss';
@import './style/svi-theme.scss';
@import './style/forms.scss';
@import './style/buttons.scss';
@import './style/overlays.scss';
@import './style/typography.scss';
@import './style/util.scss';
@import './style/smartmap.scss';
@import './style/cms.scss';
@import './svi-voter-theme.scss'; // Some overrides

@import './app/core/core.scss';
@import './app/shared/shared.module.scss';
@import './app/questionnaire/questionnaire.module.scss';
@import './app/questions-about-you/questions-about-you.module.scss';
@import './app/matching/matching.module.scss';
@import './app/profile/profile.module.scss';
@import './app/database/database.module.scss';
@include core($colors);
@include shared($colors);
@include questionnaire($colors);
@include questions-about-you($colors);
@include matching($colors);
@include profile($colors);
@include database($colors);

/* global stylings for smartspider */
.smartspider {
  textPath {
    font-size: 18px;
    @media ($screen-sm-up) {
      font-size: 10px;
    }
  }
}

/* global stylings for smartmap */
sv-smartmap {
  &.hide-me {
    circle.smartmap-position.smartmap-position-me {
      display: none;
    }
  }
}

/* global styling for candidates with official answers */
.has-official-answers {
  .matching-value {
    .number {
      position: relative;
      &::after {
        content: '*';
        position: absolute;
        right: -6px;
      }
      @media ($screen-sm-up) {
        position: initial;
        &::after {
          right: 21px;
        }
      }
    }
  }
}
