@mixin questionnaire-navigation($colors) {
  .svi-questionnaire-navigation-container {
    background-color: map-get($colors, overlay-black);
    .svi-questionnaire-navigation-question {
      background-color: map-get($colors, overlay-white);
    }
    .active {
      background-color: map-get($colors, primary-dark);
    }
    .answered {
      background-color: map-get($colors, primary);
    }
  }
}
