.button {
  background-color: map-get($colors, grey-scale-0);
  border-radius: 4px;
  font-size: 14px;
  color: map-get($colors, grey-scale-4);
  text-align: center;
  border: none;
  padding: 0.475rem 0.75rem;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:active {
    opacity: 0.9;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  > i {
    margin-right: 10px;
    color: map-get($colors, primary-dark);
  }
}

.button.button-primary {
  background-color: map-get($colors, grey-scale-4);
  color: map-get($colors, grey-scale-0);
  > i {
    color: map-get($colors, grey-scale-0);
  }
}

.button.button-large {
  padding: 0.75rem;
}

.button.icon-right {
  > i {
    margin-right: 0;
    margin-left: 10px;
  }
}

.button.button-icon {
  background-color: transparent;
  > i {
    margin-right: 0;
  }
}
