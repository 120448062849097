.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.cdk-overlay-dark-backdrop {
    opacity: 0;
  }
}

@mixin sharing-overlay($colors) {
  .svi-sharing-dialog {
    color: map-get($colors, grey-scale-4);
  }
}
