@mixin filter-group($colors) {
  .svi-matching-filter-group {
    .button-group button {
      background-color: map-get($colors, grey-scale-0);
      color: map-get($colors, grey-scale-4);
    }
    .button-group button.active {
      background-color: map-get($colors, overlay-black);
    }
    label.select-field::after {
      color: map-get($colors, grey-scale-4);
    }
  }
}
