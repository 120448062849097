@mixin profile-theme($colors) {
  .svi-profile {
    background-color: map-get($colors, overlay-white);
    color: map-get($colors, grey-scale-4);
    .social-links a {
      color: map-get($colors, grey-scale-4);
      text-decoration: none;
    }
  }
  .svi-smartspider-voter {
    fill: map-get($colors, grey-scale-4);
  }
  .svi-question-item {
    color: map-get($colors, grey-scale-4);
    .text .comment-link {
      color: map-get($colors, primary-dark);
    }
  }
  .svi-answer-option-item {
    background-color: map-get($colors, grey-scale-2);
    color: map-get($colors, grey-scale-4);
  }
  .svi-answer-option-item.selected {
    background-color: map-get($colors, primary);
  }

  .smartspider-copyright {
    opacity: 0.3;
  }
  .svi-statement {
    background-color: map-get($colors, overlay-white);
    color: map-get($colors, grey-scale-4);
  }
  .tabs {
    .answer-option-selection-header {
      color: map-get($colors, grey-scale-4);
    }
  }

  svi-question-item.active {
    background-color: map-get($colors, overlay-white);
  }
}
