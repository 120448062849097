@import '~style/variables';
@mixin page-content($colors) {
  .svi-page-content {
    background-color: map-get($colors, overlay-white);
    padding: 20px 20px;
    @media ($screen-sm-up) {
      padding: 25px 80px;
    }
  }
}
