@import '~style/svi-theme';

body,
input,
button,
select {
  font-family: 'Nunito', sans-serif;
}

h3 {
  font-size: 18px !important;
  font-weight: bold !important;
  margin: 0.5rem 0;
  color: map-get($colors, grey-scale-4);
}

@mixin default-text {
  font-size: 14px;
  line-height: 20px;
  color: map-get($colors, grey-scale-4);
}

p {
  @include default-text;
}

a {
  @include default-text;
}

p.hint {
  font-size: 13px;
  margin: 0.5rem 0 1rem 0;
}

label {
  font-size: 13px;
  color: map-get($colors, grey-scale-4);
}
