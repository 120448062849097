:host {
  display: block;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.75);
  border-width: 1px;
  border-style: solid;
  width: 100%;
  height: 100%;
  padding: 50% 0;
  overflow: auto;
}

ul.nav-list-mobile {
  background-color: #f1f1f1;
  margin: auto;
  width: 100%;
  padding: 0;
}
@mixin navbar-mobile($colors) {
  .nav-item-mobile {
    display: block;
    padding: 8px 16px;
    color: map-get($colors, grey-scale-4);
    text-decoration: none;
    text-align: center;
    border-top: 1px solid #555;
  }

  .nav-item-mobile.active {
    background-color: map-get($colors, overlay-black);
    color: black;
  }
  .nav-item-mobile.disabled {
    pointer-events: none;
    color: map-get($colors, overlay-grey);
  }
}
