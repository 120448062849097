@mixin question($colors) {
  .svi-question-container {
    background-color: map-get($colors, overlay-white);
    .category {
      color: map-get($colors, grey-scale-4);
    }
    .info-panel {
      color: map-get($colors, grayscale-0);
    }
  }
}
