@import '~style/variables';
@mixin header($colors) {
  .svi-header {
    background-color: map-get($colors, grey-scale-0);
    padding-top: 20px;
    overflow: hidden;
    .svi-header-content {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      margin-bottom: -10px;
    }
    .links {
      display: flex;
      align-self: flex-start;
    }
    .languages {
      font-size: 14px;
      margin-right: 20px;
      .language {
        text-transform: uppercase;
        cursor: pointer;
        &.active {
          text-decoration: underline;
        }
      }
      .separator {
        display: inline-block;
        &::after {
          padding: 0 10px;
          content: ' | ';
        }
        &:last-child::after {
          content: '';
        }
      }
    }
    .languages-mobile {
      font-size: 12px;
    }
    .social {
      font-size: 14px;
      color: map-get($colors, grey-scale-4);
      a {
        color: inherit;
      }
      i {
        margin-left: 20px;
        cursor: pointer;
      }
    }
    .diagonal {
      width: 100%;
      height: 20px;
      overflow: hidden;
    }
    .diagonal-left {
      float: left;
      width: 25%;
      height: 100%;
    }
    .diagonal-center {
      float: left;
      width: 120px;
      height: 100%;
    }
    polygon {
      fill: map-get($colors, layout-background);
    }
    .diagonal-right {
      overflow: hidden;
      background-color: map-get($colors, layout-background);
      height: 100%;
    }
  }
  @media ($screen-sm-up) {
    .svi-header .links {
      align-self: center;
    }
  }
}
.cdk-overlay-container {
  z-index: 1001;
}
